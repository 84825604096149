import React, {useEffect, useState} from 'react'
import '../../assets/css/newPFOffer/IngresoProductTable.css'
import op from '../../assets/img/tradicional/op.svg'
// import {OfertaIntelligence} from '../OfertaIntelligence'
import {SwitchButton} from './SwitchButton'
import {formatNumberES, redondearDecena} from '../../config'
import { ToolTipCustomList } from '../ToolTipCustomList'
import greenarrow from '../../assets/img/newPFOffer/green-arrow.svg'
import orangearrow from '../../assets/img/orange_down.svg'


export const ProductTableInnerTradicional = ({data, activateOffice, offerId, rowChangeFunctionDescOffice, 
	setModeloPopupState, setProduct}) => {
	const [estr, setestr] = useState(data.strat_price)
	const [active, setactive] = useState(data.active_office)
	const [onEdit, setOnEdit] = useState(false)
	const [MouseDownState, setMouseDownState] = useState(null)

	useEffect(() => {
		setestr(data.strat_price)
		setactive(data.active_office)
	}, [data])

	return (
		<>
			{ // active && <OfertaIntelligence setactive={setactive} />
			}

			<tr className='ProductTableInner familia1 !h-[30px]'>
				<td></td>
				<td className='border-left'></td>
				<td colSpan={3} className='border-r-[2px] border-[#B6D8EE]'>
					<div className='border-center'></div>
				</td>

				<td>
					<div className='display_flex  gray '>
						<p>
						<a
							href='#'
							onClick={(e) => {
								setProduct(data)
								setModeloPopupState(true)
							}}
						>
							{data.distributor_name}
							</a>
							</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray' >
						{ data && <SwitchButton active={active} setActive={setactive} activateOffice={activateOffice}
							id_office={data.id} offerId = {offerId}
						/>}
					</div>
				</td>

				
				<td>
					<div className={`display_flex gray`}>
						<p>{data.curr_vol}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.opt_vol}</p>
					</div>
				</td>
				<td>
					<div className={`display_flex ${parseFloat(String(data.strat_vol).split("%")[0].replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					
					
						{parseFloat(String(data.strat_vol).replace("%","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						<p>
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={[
								String(
								parseFloat(
								parseFloat(String(data.strat_vol).split(" ")[0].replace(",","."))
								- parseFloat(String(data.curr_vol).split(" ")[0].replace(",","."))
								).toFixed(1)
								).replace(".",",") + " " + String(data.curr_vol).split(",")[1].substring(1,10000)
							]}
							width={'80px'} 
							showOption='show'
							/>
						{data.strat_vol}</p>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p><ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+data.tooltip_base_pxu, "$ venta: $"+data.tooltip_base_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{data.strat_io_vol}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p><ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+data.tooltip_current_pxu, "$ venta: $"+data.tooltip_current_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{data.curr_price}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={["$ un: $"+data.tooltip_optimization_pxu, "$ venta: $"+data.tooltip_optimization_sp]}
					width={'100px'} 
					showOption='show'
					/>
						{data.opt_price}</p>
					</div>
				</td>
				<td>
					<div
						className='head_box wrap_input'
						style={{transform: 'translateX(5px)'}}
					> <span style={{fontSize: 11}}>$</span>
						<input
							id={"office_"+data.id}
							type='text'
							className='!h-[14px]'
							value={String(estr).replace("$","")}
							onChange={(e) => {
								setOnEdit(true)
								setestr(e.target.value)
							}}
							// formatNumberES
							onKeyUp={(e) =>{
								if (e.key === 'Enter') {
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									e.target.blur();
									rowChangeFunctionDescOffice(data.id, e.target.value)
								}	
							}}	
							onBlur={(e) => {
								// Lógica que quieres ejecutar cuando se presiona Enter
								if(onEdit){
									// alert(e.target.value.replace("$","").replace(".","").replace(",","."))
									setOnEdit(false)
									setestr(formatNumberES(e.target.value.replace("$","").replace(".","").replace(",",".")))
									e.target.blur();
									rowChangeFunctionDescOffice(data.id, e.target.value)
								}
							}}	
						/>
					</div>
				</td>
				<td className='border-gray-custom-blue'>
					<div className='display_flex gray '>
						<p>{data.strat_curr}</p>
					</div>
				</td>

				<td>
					<div className='display_flex gray'>
						<p>{data.curr_mg}</p>
					</div>
				</td>
				<td>
					<div className='display_flex gray'>
						<p>{data.opt_mg}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className={`display_flex   ${parseFloat(String(data.strat_mg).replace("$","").replace(".","").replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px', color: '#13acaa', fontWeight: 500}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p>
					
					{parseFloat(String(data.strat_mg).replace("$","").replace(".","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={["RO Total: $"+data.tooltip_total_ro, "RO Unit: $"+data.tooltip_unit_ro, "$ Crit:"+data.critical_price]}
							width={'120px'} 
							showOption='show'
							/>{data.strat_mg}</p>
					</div>
				</td>

				<td>
					<div className={`display_flex   ${parseFloat(String(data.strat_mg).replace("$","").replace(".","").replace(",", ".")) > 0 ? 'green' : 'orange'}`} style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px', color: '#13acaa', fontWeight: 500}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p className=' !text-[#FF5334]'>
					
					{parseFloat(String(data.strat_mg).replace("$","").replace(".","").replace(",",".")) > 0 ? 
						<img src={greenarrow} alt='' />
						:
						<img src={orangearrow} alt='' />}
						
						<ToolTipCustomList 
							// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
							list={["RO Total: $"+data.tooltip_total_ro, "RO Unit: $"+data.tooltip_unit_ro, "$ Crit:"+data.critical_price]}
							width={'120px'} 
							showOption='show'
							/>
						{data.strat_ro_pct}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray' >
						<p className='ten'>{data.uplift}</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray' style={{cursor: 'pointer', position: 'relative',
					fontSize: '11px'}}
					onMouseUp={(e) => {
						const Tooltip = e.target.querySelector('.ToolTipCustom')
						if(Tooltip){
							// Tooltip.classList.remove('show')
							Tooltip.style.display = 'none'
						}
						clearInterval(MouseDownState)
					}}
					onMouseDown={(e) => {
						console.log("BOTON TOOLTIP::: ")
						console.log(e.target);

						if (e.button != 0) {
							return false
						}
						let MouseDownTimeOut = setTimeout(() => {
							const Tooltip = e.target.querySelector('.ToolTipCustom')
							// Tooltip.classList.add('show')
							Tooltip.style.display = 'block'
							console.log("Apareciendo Tooltip:....")
						}, [50])

						setMouseDownState(MouseDownTimeOut)
					}}
					onMouseLeave={(e) => {
						const Tooltip = e.currentTarget.querySelector('.ToolTipCustom');
						if (Tooltip) {
						  Tooltip.style.display = 'none';
						}
					  }}
					>
					<p className='ten'>
					<ToolTipCustomList 
					// value={`$Unidad: $${product?.tooltips?.price_opt?.pxu} $Venta: $${product?.tooltips?.price_opt?.pv}`}
					list={[data.strat_ro_price]}
					width={'100px'} 
					showOption='show'
					/>
						
						{data.incr_ro}%</p>
					</div>
				</td>
				<td>
					<div className='display_flex   gray'>
						<p className='ten'>{data.recommended_pvp}</p>
					</div>
				</td>

				<td className='border-gray-custom-blue'>
					<div className='display_flex gray'>
						<p className='ten'>{data.elasticity}</p>
					</div>
				</td>
				<td></td>

				<td>
					<div className='display_flex gray'>
						{ /*
						<img
							src={op}
							alt=''
							onClick={(e) => {
								setactive(true)
							}}
							style={{cursor: 'pointer'}}
							className='-translate-x-[5px]'
						/>
						*/ }
					</div>
				</td>
			</tr>
		</>
	)
}
