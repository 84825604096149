import PromoApi from '../utils/api/apiPromoboosterPF'
import { envVariables } from '../utils/envVariables'
import { config } from '../config'

export const getModernTrackig = async (promotion_id) => {
	const token = localStorage.getItem('session')
	return PromoApi.get(
		`${config.apiUrl}/catalog/line_tracking/${promotion_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTraditionalTrackig = async (promotion_id) => {
	const token = localStorage.getItem('session')
	return PromoApi.get(
		`${config.apiUrl}/traditional_catalog/line_tracking/${promotion_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	)
}

export const getTraditionalOfferTrackig = async (promotion_id) => {
	const token = localStorage.getItem('session')
	return PromoApi.get(
		`${config.apiUrlTradOferta}/offer/brand_tracking/${promotion_id}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	)
}
